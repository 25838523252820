import React, { useEffect, useRef, useState } from 'react'
import './badge-view.styles.scss'
import BackButton from '@/components/back-button/back-button.component'
import { useNavigate, useParams } from 'react-router-dom'
import { getRealTimeQueryFromDb, getUniqueDocFromDb } from '@/utils/getDataFromDb'
import { format } from 'date-fns'
import { properCase } from '@/utils/properCase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { ModalAlert } from '@/components/SHARED/modal/modal.component'

const BadgeView = ({
    earnedBadges
}) => {
    
    const [ badgeRecord, setBadgeRecord ] = useState(null);
    const [ mcData, setMcData ] = useState(null);
    const [ compiledData, setCompiledData ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const navigate = useNavigate();
    const { badgePath } = useParams();
    const dataRef = useRef();

    useEffect(() => {
        if (!mcData) return;
        document.getElementById('download-image-button').addEventListener('click', async function() {            
            // Create a temporary anchor element
            try {
                // Fetch the image as a blob
                const response = await fetch(mcData.imageUrl);
                const blob = await response.blob();

                // Create a temporary anchor element
                const a = document.createElement('a');
                
                // Create an object URL for the blob
                const url = URL.createObjectURL(blob);
                
                // Set the download attribute with the desired filename
                a.href = url;
                a.download = mcData.imageUrl.split('/').pop();  // Use the image filename from the URL
                
                // Trigger the download by simulating a click
                a.click();
                
                // Release the object URL after download
                URL.revokeObjectURL(url);
            } catch (err) {
                console.error('Error fetching the image:', err);
            }
        });
    }, [mcData])

    useEffect(() => {
        if (!badgePath || !earnedBadges) return;
        let badgeRec = Object.values(earnedBadges).filter(b => b.path === badgePath)[0]
        setBadgeRecord(badgeRec);
        getBadgeData(badgeRec);
        getStats(badgeRec.mc_key, badgeRec.cst_key);
    }, [badgePath, earnedBadges])

    function getBadgeData(badgeRec) {
        getUniqueDocFromDb({
            'path': `mcs/${badgeRec.mc_key}`,
            'once': true,
            'callback': callback
        })
        function callback(data) {
            console.log(data);
            data.post_text = `I’m happy to share that I’ve obtained a new certification: ${data.name} from Ed Leaders Network! ${data.infoLink}`;
            setMcData(data);
        }
    }

    function getStats(mc_key, cst_key) {
        const progressId = `${mc_key}_${cst_key}`;
        console.log(progressId);
        getRealTimeQueryFromDb({
            'path': 'compiledData',
            'child': 'progressId',
            'value': progressId,
            'callback': callback
        })
        function callback(data) {
            console.log(data);
            let sortedData = Object.values(data)
            .sort((a,b) => a.ts > b.ts ? 1 : -1)
            setCompiledData(sortedData);
        }
    }

    function saveTextToClipboard() {
        navigator.clipboard.writeText(mcData.post_text);
        setAlertMessage("The text has been copied to your clipboard!");
    }

    function downloadImage() {
        let link = document.createElement('a');
        link.href = mcData.imageUrl;
        link.download = 'badgeimage.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div className='badge-view'>
            <BackButton cancel={() => navigate(-1)} />
            <div className="g-space-1"></div>
            <div className='card-head'>
                {
                    (badgeRecord) && 
                    <div className='badge-title'>
                        <img src={badgeRecord.mc_imageUrl} className='badge-icon' />
                        {badgeRecord.mc_name}
                    </div>
                }
            </div>
            {
                (mcData) &&
                <p className='meta'>{mcData.summary}</p>
            }
            <div className='badge-view-grid'>
                <div className='g-list-item share-box'>
                    {
                        (mcData) &&
                        <>
                        <b>Share This Badge</b>
                        <hr className='no-margin' />
                        <div>Badge Image</div>
                        <div>
                            <button 
                                className='g-button small-btn'
                                id='download-image-button' >
                                Download
                                <FontAwesomeIcon icon={faImage} />
                            </button>
                        </div>
                        <div className="g-space-1"></div>
                        <div className='text-head'>
                            Sample Text:
                            <button 
                                className='g-button small-btn'
                                onClick={() => saveTextToClipboard()}>
                                <FontAwesomeIcon icon={faCopy} title='Copy Text' />
                            </button>
                        </div>
                        <div className='text meta'>
                            {
                                (mcData) &&
                                `"${mcData.post_text}"`
                            }
                        </div>
                        </>
                    }
                </div>
                <div className='g-list-item data-box'>
                    <b>Timeline</b>
                    <hr className='no-margin' />
                    {
                        (compiledData) &&
                        compiledData.map(d => {
                            console.log(d);
                            return (
                            <div key={d.id} className='data-row meta'>
                                <div className='date'>
                                    {format(new Date(d.ts), "P")}
                                </div>
                                <div className='action'>
                                    {properCase(d.type.replace('-', ': '))}
                                </div>
                            </div>
                            )
                        })
                    }
                </div>
            </div>
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage} />
        </div>
    )
}

export default BadgeView