import React, { useEffect } from 'react';
import './my-badges-container.styles.scss';
import { format } from 'date-fns';
import { Route, Routes, useNavigate } from 'react-router-dom';
import BadgeView from './badge-view.component';

const MyBadgesContainer = ({ earnedBadges }) => {

    const navigate = useNavigate();

    return (
        <div className='my-badges-container'>
            <div className='g-card badges-card'>
                <div className='section-title'>My Badges</div>
                <hr className='no-top-margin' />
                <Routes>
                    <Route path='' element={
                        <div className='badge-list'>
                            {
                                (earnedBadges && Object.values(earnedBadges).length > 0) 
                                ?
                                Object.values(earnedBadges)
                                .sort((a,b) => a.mc_name.toLowerCase() > b.mc_name.toLowerCase() ? 1 : -1)
                                .map(b => (
                                    <button 
                                        key={b.mc_key} 
                                        className='g-button'
                                        onClick={() => navigate(b.path)}>
                                        <img src={b.mc_imageUrl} className='badge-image' />
                                        <div className='title'>
                                            {b.mc_name}
                                        </div>
                                        <div className='meta'>Earned: {format(new Date(b.ts), "P")}</div>
                                    </button>
                                ))
                                :
                                <p>No Earned Badges Yet...</p>
                            }
                        </div>
                    }></Route>
                    <Route path=':badgePath' element={
                        <BadgeView earnedBadges={earnedBadges} />
                    }></Route>
                </Routes>
            </div>
        </div>
    )
}

export default MyBadgesContainer