import React, { useEffect, useState } from 'react';
import './view-user-data.styles.scss';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import BackButton from '@/components/back-button/back-button.component';
import { getUniqueDocFromDb, getUserMcsFromDb } from '@/utils/getDataFromDb';
import StatusBarWithIcons from '@/components/HOME/my-microcredentials/status-bar-with-icons.component';
import { UseCloudFunction } from '@/utils/firebase.utils';

const ViewUserData = ({ mcList, regs, nfeRegs, users, progressNumbers, sortCriteria, sortCriteriaLists }) => {

    const [ currentUser, setCurrentUser ] = useState(null);
    const [ partMcs, setPartMcs ] = useState(null);
    const [ evalMcs, setEvalMcs ] = useState(null);
    const [ criteriaListsState, setCriteriaListsState ] = useState(null);
    const [ userSortCriteriaState, setUserSortCriteriaState ] = useState(null);
    const { userId } = useParams();
    const navigate = useNavigate();
    let mcListener;

    useEffect(() => {
        if (!userId || !users || !users[userId]) {return;}
        setCurrentUser(users[userId]);

        return () => {
            if (mcListener) mcListener();
        }
    }, [userId, users])

    useEffect(() => {
        if (!regs || !mcList || !userId) return;
        console.log(regs);
        getUserRegs();
    }, [regs, mcList, userId])

    useEffect(() => {
        if (!currentUser) return;
        setUserSortCriteriaState(currentUser.sortCriteria ? [...currentUser.sortCriteria] : [])
    }, [currentUser])

    useEffect(() => {
        if (!sortCriteriaLists) return;
        setCriteriaListsState({...sortCriteriaLists});
    }, [sortCriteriaLists])

    function getUserRegs() {
        let partObj = {};
        let evalObj = {};
        for (let mc of Object.values(regs)) {
            // console.log(mc);
            const roles = mc.roles[userId];
            if (!roles) continue;
            if (roles.includes('part')) {
                partObj[mc.mc_key] = mcList[mc.mc_key];
            }
            if (roles.includes('eval')) {
                evalObj[mc.mc_key] = mcList[mc.mc_key];
            }
        }
        setPartMcs(partObj);
        setEvalMcs(evalObj);
    }

    async function saveSortCriteriaList(criteriaId, progId) {
        let checked = true;
        let tempCriteria = {...criteriaListsState[criteriaId]}
        let tempList = [...tempCriteria.progressList];
        if (tempList.includes(progId)) {
            checked = false;
            tempList.splice(tempList.indexOf(progId), 1)
        } else {
            tempList.push(progId);
        }
        tempCriteria = {...tempCriteria, ...{'progressList': tempList}}
        setCriteriaListsState({...criteriaListsState, ...{[criteriaId]: tempCriteria}})
        const res = await UseCloudFunction(
            'saveProgToCriteriaList', 
            {
                "progIdArr": [progId],
                "criteriaId": criteriaId,
                "add": checked
            }
        )
        // console.log(JSON.stringify(res));
    }

    async function saveGlobalSortCriteriaList(criteriaId) {
        let checked = true;
        let tempUserCriteria = [...userSortCriteriaState];
        if (tempUserCriteria.includes(criteriaId)) {
            checked = false;
            tempUserCriteria.splice(tempUserCriteria.indexOf(criteriaId), 1)
        } else {
            tempUserCriteria.push(criteriaId)
        }
        setUserSortCriteriaState([...tempUserCriteria]);
        let progArr = []
        for (let mc of Object.values(partMcs)) {
            progArr.push(`${mc.mcData.mc_key}_${currentUser.cst_key}`);
        }
        const res = await UseCloudFunction(
            'saveProgToCriteriaList', 
            {
                "progIdArr": progArr,
                "criteriaId": criteriaId,
                "add": checked,
                "global": true,
                "cst_key": currentUser.cst_key,
                "newCriteriaList": tempUserCriteria
            }
        )
        // console.log(JSON.stringify(res));
    }

    function cancel() {
        navigate(-1)
    }

    return (
        <div className='view-user-data'>
            <BackButton cancel={cancel} />
            <div className="g-space-1"></div>
            {
                (currentUser) &&
                <div className='g-card'>
                    <div className='user-title'>
                        <div>
                            <UserIcon profile={currentUser} large={true} />
                        </div>
                        <div className='user-name'>
                            <div>{currentUser.fName} {currentUser.lName}</div>
                            <div className='email'>
                                <a href={`mailto:${currentUser.email}`} target="_blank">
                                    <button className='g-button text-only'>{currentUser.email}</button>
                                </a>
                            </div>
                            
                        </div>
                        <div className='user-info'>
                            Role(s): 
                                {currentUser.permiss_part ? ' Participant' : ''}
                                {currentUser.permiss_part && (currentUser.permiss_eval || currentUser.permiss_admin) ? ',' : ''}
                                {currentUser.permiss_eval ? ' Evaluator' : ''}
                                {currentUser.permiss_eval && currentUser.permiss_admin ? ',' : ''}
                                {currentUser.permiss_admin ? ' Admin': ''}<br />
                            State: {currentUser.state}
                            {currentUser.state === 'IL' ? <><br />IEIN: {currentUser.iein}</> : null}
                            
                        </div>
                        <div className='meta'><button className='g-button text' onClick={() => navigate(`/admin/edituser/${currentUser.cst_key}`)}><FontAwesomeIcon icon={faPen} /></button></div>
                    </div>
                    <hr />
                    <div className='field'>
                        <label className='no-margin'>
                            Global Sort Criteria
                        </label>
                        <div className='meta'>
                            Changing these global sort criteria will change the sorting for each of this user's Micro-Credentials.
                        </div>
                        <div className="g-space-1"></div>
                        {
                            (sortCriteria) &&
                            Object.values(sortCriteria)
                            .filter(st => st.status === 'active')
                            .sort((a,b) => a.sortTitle.toLowerCase() > b.sortTitle.toLowerCase() ? 1 : -1)
                            .map(st => (
                                <div key={st.id} className='field-checkbox'>
                                    <label>{st.sortTitle}</label>
                                    <input 
                                        type='checkbox' 
                                        checked={
                                            userSortCriteriaState && 
                                            userSortCriteriaState.includes(st.id) 
                                            ? 
                                            true 
                                            : 
                                            false
                                        } 
                                        onChange={(e) => saveGlobalSortCriteriaList(st.id)} 
                                    />
                                </div>
                            ))
                        }
                    </div>
                    <hr />
                    {
                        (partMcs && Object.values(partMcs).length > 0) &&
                        <>
                        <div className='section-title'>
                            Participating
                        </div>
                        
                        {
                            Object.values(partMcs).sort((a,b) => {
                                if (progressNumbers && progressNumbers[a.mc_key] && progressNumbers[b.mc_key]) {
                                    const aProgData = progressNumbers[a.mc_key];
                                    const bProgData = progressNumbers[b.mc_key];
                                    if (aProgData.ts > bProgData.ts){
                                        return 1;
                                    } else {
                                        return -1;
                                    }
                                } else if (progressNumbers && progressNumbers[a.mc_key]) {
                                    return 1;
                                } else if (a.mcData) {
                                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                                        return 1;
                                    } else {
                                        return -1;
                                    }
                                } else {
                                    return 1;
                                }
                            })
                            .map(mc => (
                                <div key={mc.mc_key} className='g-list-item' >
                                    <div className="g-space-1"></div>
                                    <div className=' progress-bar'>
                                        <div className='title-box'>
                                            <img src={mc.imageUrl} />
                                            {mc.name}
                                        </div>
                                        <div className='icon-box'>
                                            <StatusBarWithIcons progressNumber={progressNumbers[mc.mc_key] ? progressNumbers[mc.mc_key][userId] : 0} mcData={mc} />
                                        </div>
                                    </div>
                                    {/* <div className="g-space-1"></div> */}
                                    <hr />
                                    <p><b>Sort Criteria</b></p>
                                    <div className='sort-criteria-list'>
                                    {
                                        (sortCriteria && criteriaListsState) &&
                                        Object.values(sortCriteria)
                                        .filter(st => st.status === 'active')
                                        .sort((a,b) => a.sortTitle.toLowerCase() > b.sortTitle.toLowerCase() ? 1 : -1)
                                        .map(st => (
                                            <div key={st.id} className='field-checkbox'>
                                                <label>{st.sortTitle}</label>
                                                <input 
                                                    type='checkbox' 
                                                    checked={
                                                        criteriaListsState[st.id] && 
                                                        criteriaListsState[st.id].progressList.includes(`${mc.mc_key}_${currentUser.cst_key}`) 
                                                        ? 
                                                        true 
                                                        : 
                                                        false
                                                    } 
                                                    onChange={(e) => saveSortCriteriaList(st.id, `${mc.mc_key}_${currentUser.cst_key}`)} 
                                                />
                                            </div>
                                        ))
                                    }
                                    </div>
                                </div>
                            ))
                        }
                        </>
                    }
                    <div className="g-space-2"></div>
                    {
                        (evalMcs && Object.values(evalMcs).length > 0) &&
                        <>
                        <div className='section-title'>
                            Evaluating
                        </div>
                        {
                            Object.values(evalMcs).sort((a,b) => a.name > b.name ? 1 : -1)
                            .map(mc => (
                                <div key={mc.mc_key} className='g-list-item progress-bar'>
                                    <div className='title-box'>
                                        <img src={mc.imageUrl} />
                                        {mc.name}
                                    </div>
                                    <div className='icon-box'>
                                        
                                    </div>
                                </div>
                            ))
                        }
                        </>
                    }
                    <div className="g-space-1"></div>
                    <div className='meta'>
                        {currentUser.cst_key}
                    </div>
                </div>
            }
        </div>
    )
}

export default ViewUserData;