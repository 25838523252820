import React, { useEffect, useRef, useState } from 'react'
import './create-user.styles.scss'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import FindUserInDb from './find-user-in-db.component';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useDispatch, useSelector } from 'react-redux';
import { ModalAlert, ModalConfirm } from '@/components/SHARED/modal/modal.component';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import BackButton from '@/components/back-button/back-button.component';

const CreateUser = ({ oldUsers, users }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ searchDb, setSearchDb ] = useState(false);
    const [ dbData, setDbData ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ criteriaListsState, setCriteriaListsState ] = useState(null);
    const [ req ] = useState(['email','fName','lName']) 
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dataRef = useRef();
    const confirmRef = useRef();
    const { importId, userId } = useParams();

    useEffect(() => {
        if (!userId) {return;}
        const userData = users[userId];
        dataRef.current = userData;
        setFormData(dataRef.current);
        // console.log(dataRef.current);
        setSearchDb(true);
    }, [userId])

    useEffect(() => {
        if (importId && oldUsers) {
            // console.log(importId)
            dataRef.current = oldUsers[importId]
            setFormData(dataRef.current)
            if (dataRef.current.courses) {
                for (let c of Object.values(dataRef.current.courses)) {
                    if (c.evals) {
                        saveData('permiss_eval', true);
                    }
                    if (c.parts) {
                        saveData('permiss_part', true);
                    }
                } 
            }
            if (dataRef.current.admin) {
                saveData('permiss_admin', true);
            }
            // setSearchDb(true);
        }
    }, [importId, oldUsers])

    useEffect(() => {
        if (!dbData) {return;}
        saveData('permiss_part', true);
    }, [dbData])

    function saveData(key, value) {
        if (key === 'sortCriteria') {
            let criteriaArr = dataRef.current.sortCriteria ?? [];
            if (criteriaArr.includes(value)) {
                criteriaArr.splice(criteriaArr.indexOf(value), 1);
            } else {
                criteriaArr.push(value);
            }
            dataRef.current = {...dataRef.current, ...{[key]: criteriaArr}};
        } else {
            dataRef.current = {...dataRef.current, ...{[key]: value}};
        }
        setFormData(dataRef.current);
        // console.log(dataRef.current);
        return;
    }
    
    function cancelUser() {
        dataRef.current = {}
        setFormData(dataRef.current);
        setLoading(false);
        navigate(-1)
    }

    async function activateUser() {
        setConfirmMessage('Are you sure you want to reactivate this user? If the user is a Region Leader, they will be assigned "Viewer" permissions and can only view their assigned region\'s data.');
        confirmRef.current = () => {
            const newObj = {...dataRef.current, ...{'status': 'active'}}
            if (newObj.permissions === 'regionLeader') {
                newObj.permissions = 'viewer'
            } 
            dataRef.current = newObj;
            handleSubmit();
        }        
    }

    async function deactivateUser() {
        setConfirmMessage('Are you sure you want to deactivate this user? Going forward, they will not be able to sign into the platform.');
        confirmRef.current = () => {
            const newObj = {...dataRef.current, ...{'status': 'inactive'}}
            dataRef.current = newObj;
            handleSubmit();        
        }
    }

    async function precheckSubmit() {
        let profile = {...dataRef.current};
        for (let r of req) {
            if (!profile[r]) {
                setAlertMessage('Please complete the form before moving on.');
                return;
            }
        }
        if (!profile.permiss_admin && !profile.permiss_eval && !profile.permiss_part) {
            setConfirmMessage('This user does not have any permissions assigned. If this is intended, click "OK". If not, click "Cancel" and assign permissions before saving this user. Permissions can be changed later.');
            confirmRef.current = () => {
                handleSubmit(profile);
                return;
            }        
        } else {
            handleSubmit(profile);
        }
    }

    async function handleSubmit(profile) {
        
        setLoading(true);
        if (!dbData || !dbData.ind_cst_key) {
            setAlertMessage('Please find the user in the IPA database before continuing.');
            return;
        }
        profile.status = 'active';
        profile.state = dbData.adr_state;
        profile.city = dbData.adr_city;
        profile.cst_key = dbData.ind_cst_key;
        profile.recno = dbData.cst_recno;
        profile.iein = dbData.ind_ein_number_ext;
        profile.uid = dbData.ind_cst_key;
        // console.log(profile);
        const res = await UseCloudFunction('updateProfile', {'profile': profile, 'cst_key': userProfile.cst_key, 'imported': importId ? true : false})
        // console.log(res);
        if (res.success) {
            setAlertMessage('User saved correctly.');
            cancelUser();
        } else {
            setAlertMessage('Something went wrong. Try again later.');
            return;
        }
    }

    return (
        <div className='create-div'>
            <BackButton cancel={cancelUser} />
            <div className='g-space-20'></div>
            <div className='g-card settings-card'>
                <div className='create-user-head'>
                    <h4>{formData && formData.ts ? 'Edit User' : formData && formData.id ? 'Import User' : 'Create a New User'}</h4>
                    {
                        (formData.current && formData.current.status === 'inactive') &&
                        <h4 className='required'>INACTIVE</h4>
                    }
                </div>
                <hr />
                <div className='field'>
                    <label>Email</label>
                    <input type='text' value={formData.email ?? ''} onChange={(e) => saveData('email', e.target.value.toLowerCase().trim())} />
                </div>
                <div className='field'>
                    <label>Second Email</label>
                    <input type='text' value={formData.email2 ?? ''} onChange={(e) => saveData('email2', e.target.value.toLowerCase().trim())} />
                </div>
                <div className="g-double-col">
                    <div className='field'>
                        <label>First Name</label>
                        <input type='text' value={formData.fName ?? ''} onChange={(e) => saveData('fName', e.target.value)} />
                    </div>
                    <div className='field'>
                        <label>Last Name</label>
                        <input type='text' value={formData.lName ?? ''} onChange={(e) => saveData('lName', e.target.value)} />
                    </div>
                </div>
                <div className="g-space-20"></div>
                <div className='field'>
                    <label>NetForum Information</label>
                    <FindUserInDb 
                        users={users} 
                        user_cst_key={formData.cst_key} 
                        email={formData.email} 
                        saveData={saveData} 
                        searchDb={searchDb} 
                        setSearchDb={setSearchDb} 
                        dbData={dbData} 
                        setDbData={setDbData} />    
                </div>
                <div className="g-space-20"></div>
                
                <div className='field'>
                    <label>User Permissions</label>
                    <div className='field-checkbox'>
                        <label>Admin</label>
                        <input type='checkbox' checked={formData.permiss_admin ? true : false} onChange={(e) => saveData('permiss_admin', e.target.checked)} />
                    </div>
                    <div className='field-checkbox'>
                        <label>Participant</label>
                        <input type='checkbox' checked={formData.permiss_part ? true : false} onChange={(e) => saveData('permiss_part', e.target.checked)} />
                    </div>
                    <div className='field-checkbox'>
                        <label>Evaluator</label>
                        <input type='checkbox' checked={formData.permiss_eval ? true : false} onChange={(e) => saveData('permiss_eval', e.target.checked)} />
                    </div>
                </div>
                <hr />
                <div className='create-user-buttons'>
                    <div className='activate-button'>
                    {
                        (!importId)
                        ?
                        (formData.status === 'inactive') 
                        ?
                        <button className='g-button' onClick={() => activateUser()}>Re-activate User</button>
                        :
                        <button className='g-button' onClick={() => deactivateUser()}>Make Inactive</button>
                        :
                        null
                    }
                    </div>
                    <div className='approve-buttons'>
                        <button className='g-button' onClick={() => cancelUser()}>Cancel</button>
                        {
                            (loading)
                            ?
                            <button className='g-button'><ThreeDotsLoader /></button>
                            :
                            (dbData)
                            ?
                            <button className='g-button primary' onClick={() => precheckSubmit()}>Save User</button>
                            :
                            <button className='g-button disabled'>Save User</button>
                        }
                    </div>
                </div>
            </div>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={confirmRef.current} />
        </div>
    )
}

export default CreateUser