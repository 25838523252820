import React, { useEffect, useRef, useState } from 'react'
import './new-mc-check.styles.scss'

import Modal, { ModalAlert } from '@/components/SHARED/modal/modal.component'
import { useSelector } from 'react-redux'
import { getIndividualMc } from '@/utils/getDataFromDb'
import { UseCloudFunction } from '@/utils/firebase.utils'
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component'
import { getPartEmailData } from '@/utils/getPartEmailData'
import FullPageLoader from '@/components/SHARED/loader/full-page-loader.component'

const NewMcCheck = () => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const myMcList = useSelector(state => state.contentData.myMcList);
    const [ newMcList, setNewMcList ] = useState([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const fetchedRef = useRef();

    useEffect(() => {
        if (!userProfile || !myMcList) {return;}
        getUserMcListFromNfe();
        fetchedRef.current = true;
    }, [userProfile, myMcList])

    useEffect(() => {
        if (newMcList.length === 0) return;
        setShowModal(true);
    }, [newMcList])

    async function getUserMcListFromNfe() {
        if (fetchedRef.current) return;
        setNewMcList([])
        const list = await UseCloudFunction('getUserMcListFromNfe', {'profile': userProfile})
        let newList = []
        for (let e of list) {
            const {evt_key, add_date, sortData } = e;
            // console.log(evt_key);
            if (!myMcList || !myMcList[evt_key]) {
                let mcDoc = await getIndividualMc({'mc_key': evt_key})
                // console.log(mcDoc);
                // if (!sortData['@xsi:nil']) {
                //     mcDoc.sortData = [sortData];
                // }
                mcDoc.user_add_date = new Date(add_date).getTime();
                newList.push(mcDoc);
                // break;
            }
        }
        console.log(newList);
        if (newList.length === 0) {return;}
        setNewMcList(newList.sort((a,b) => a.name > b.name ? 1 : -1));
    }

    async function approveNewMcs() {
        setLoading(true);
        setShowModal(false);
        const newMcArr = newMcList.map(m => {return {'mc_key': m.mc_key, 'user_add_date': m.user_add_date}});
        // console.log(newMcArr);
        const res = await UseCloudFunction(
            'approveNewMcList', 
            {
                'newMcList': newMcArr, 
                'profile': userProfile
            }
        )
        console.log(res);
        if (res.error) {
            setAlertMessage(`Something went wrong. Please try again later. (Error: ${res.error}`);
        } 
        
        setNewMcList([])
        setLoading(false);
    }

    return (
        <div className='new-mc-check'>
            <Modal show={showModal} cancel={() => setShowModal(false)} >
                <div id='new-mc-check-modal-content'>
                    {
                        (!alertMessage)
                        ?
                        <>
                        <h2>You have been registered for {newMcList.length === 1 ? 'A New Micro-Credential!' : `${newMcList.length} New Micro-Credentials!`}</h2>
                        <div className='g-space-20'></div>
                        <div className='modal-mc-list'>
                            {/* <div className='modal-mc-item g-list-item'>
                                <div style={{"height": "100px"}} >

                                </div>
                            </div>
                            <div className='modal-mc-item g-list-item'>
                                <div style={{"height": "100px"}} >
                                    
                                </div>
                            </div>
                            <div className='modal-mc-item g-list-item'>
                                <div style={{"height": "100px"}} >
                                    
                                </div>
                            </div>
                            <div className='modal-mc-item g-list-item'>
                                <div style={{"height": "100px"}} >
                                    
                                </div>
                            </div>
                            <div className='modal-mc-item g-list-item'>
                                <div style={{"height": "100px"}} >
                                    
                                </div>
                            </div>
                            <div className='modal-mc-item g-list-item'>
                                <div style={{"height": "100px"}} >
                                    
                                </div>
                            </div>
                            <div className='modal-mc-item g-list-item'>
                                <div style={{"height": "100px"}} >
                                    
                                </div>
                            </div>

                            <div className='modal-mc-item g-list-item'>
                                <div style={{"height": "100px"}} >
                                    
                                </div>
                            </div> */}
                        {
                            newMcList.map(m => (
                                <div key={m.mc_key} className='modal-mc-item g-list-item'>
                                    <img src={m.imageUrl} className='icon' />
                                    <div>
                                        <div className='title'>{m.name}</div>
                                        <div className='desc'>{m.summary}</div>
                                    </div>
                                </div>
                            ))
                        }
                        </div>
                        <div className='g-space-30'></div>
                        <div className='buttons'>
                            {
                                (loading)
                                ?
                                <>
                                    <button className='g-button'>Cancel</button>
                                    <button className='g-button primary'><ThreeDotsLoader /></button>
                                </>
                                :
                                <>
                                    <button className='g-button' onClick={() => setShowModal(false)}>Cancel</button>
                                    <button className='g-button primary' onClick={() => approveNewMcs()}>Get Started</button>
                                </>
                            }
                        </div>
                        </>
                        :
                        <>
                        <h4>Sorry, something went wrong. Please try again later.</h4>
                        <div className="buttons">
                            <button className="g-button" onClick={() => setShowModal(false)}>
                                Ok
                            </button>
                        </div>
                        </>
                    }
                </div>
            </Modal>
            <ModalAlert 
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage} />
            <FullPageLoader show={loading} />
        </div>
    )
}

export default NewMcCheck