import React, { useEffect, useRef, useState } from 'react'
import './my-microcredentials.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { UseCloudFunction } from '@/utils/firebase.utils';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import { setMyProgress } from '@/state/slices/contentSlice';
import NewMcCheck from '../new-mc-check/new-mc-check.component';
import StatusBarWithIcons from './status-bar-with-icons.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faDownload, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { ModalAlert } from '@/components/SHARED/modal/modal.component';
import CsvDownload from 'react-json-to-csv';
import { format } from 'date-fns';
import { getRealTimeQueryFromDb } from '@/utils/getDataFromDb';

const MyMicrocredentials = ({ evaluatingList, userProgressNumbers }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const myMcList = useSelector(state => state.contentData.myMcList);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ toImport, setToImport ] = useState([]);
    const [ loading, setLoading ] = useState([]);
    const [ transcriptLoading, setTranscriptLoading ] = useState(false);
    const [ transcriptData, setTranscriptData ] = useState(null);
    const loadingRef = useRef();
    const importRef = useRef();
    const transcriptRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!myMcList) return;
        loadingRef.current = Object.values(myMcList).map(m => m.mc_key)
        // setLoading(loadingRef.current);
        
    }, [myMcList])

    useEffect(() => {
        if (!transcriptData) return;
        document.getElementById('csv-download-button').click();
        setTranscriptLoading(false);
    }, [transcriptData])

    async function importOldProgress(e, mc_key) {
        setLoading(true);
        const mcId = e.substring(0, e.indexOf('-'));
        console.log(mcId);
        const res = await UseCloudFunction(
            'postDataFromOldDatabase', 
            {
                'url': 'https://us-central1-ipa-microcredentials.cloudfunctions.net/app/api/get-participant-data', 
                'body': {
                    'mcId': mcId, 
                    'email': userProfile.email,
                    'email2': userProfile.email2 ?? null
                }
            }
        );
        console.log(res);
        if (res.error) {
            setAlertMessage('Something went wrong while getting your data from version 1. Please try again later.');
            setLoading(false);
            return;
        }
        const res2 = await UseCloudFunction(
            'importOldProgress', 
            {
                'cst_key': userProfile.cst_key, 
                'mc_key': mc_key, 
                'progress': res.oldData
            }
        )
        console.log(res2);
        if (res2.error) {
            setAlertMessage('Something went wrong saving your old data to version 2. Please try again later.');
            setLoading(false);
            return;
        }
        let newMcData = {...myMcList[mc_key]};
        newMcData.imported = false;

    }

    async function getTranscript() {
        setTranscriptLoading(true);
        getRealTimeQueryFromDb({
            'path': 'compiledData',
            'child': 'cst_key',
            'value': userProfile.cst_key,
            'callback': callback
        })
        function callback(data) {
            console.log(data);
            let dataObj = {};
            for (let mc of Object.values(myMcList)) {
                let obj = {
                    'MC ID': mc.mc_key,
                    'MC Name': mc.name,
                    'Purchased': null,
                    'Materials': null,
                    'Precheck Submitted': null,
                    'Precheck Approved': null,
                    'Portfolio Submitted': null,
                    'Portfolio Approved': null
                }
                dataObj[mc.mc_key] = obj;
            }
            console.log(dataObj);
            for (let d of Object.values(data)) {
                if (!dataObj[d.mc_key]) continue;
                const dateString = format(new Date(d.ts), "P");
                if (d.type === 'progress-purchased') {
                    dataObj[d.mc_key]['Purchased'] = dateString;
                }
                else if (d.type === 'materials-viewed') {
                    dataObj[d.mc_key]['Materials'] = dateString;
                }
                else if (d.type === 'precheck-submitted') {
                    dataObj[d.mc_key]['Precheck Submitted'] = dateString;
                }
                else if (d.type === 'precheck-approved') {
                    dataObj[d.mc_key]['Precheck Approved'] = dateString;
                }
                else if (d.type === 'portfolio-submitted') {
                    dataObj[d.mc_key]['Portfolio Submitted'] = dateString;
                }
                else if (d.type === 'portfolio-approved') {
                    dataObj[d.mc_key]['Portfolio Approved'] = dateString;
                }
            }
            transcriptRef.current = Object.values(dataObj);
            setTranscriptData(transcriptRef.current);
            
        }
    }

    return (
        <div className='my-microcredentials-container'>
            <div className='my-microcredentials-content'>
                {
                    (myMcList) &&
                    <div className='g-card'>
                        <div className='section-title'>
                            My Micro-Credentials
                            {
                                (transcriptData) &&
                                <CsvDownload
                                    id='csv-download-button'
                                    data={transcriptData}
                                    filename={`eln_mc_transcript_${(format(new Date(), "Pp")).replace(/[^A-Za-z0-9]/g, '')}.csv`}
                                    className='hide g-button small-btn'
                                    delimiter=','
                                    title='Download Transcript'>
                                    <FontAwesomeIcon icon={faDownload} />
                                </CsvDownload>
                            }
                            <button 
                                className={`g-button small-btn ${transcriptLoading ? 'disabled' : ''}`}
                                onClick={() => getTranscript()}>
                                {
                                    (transcriptLoading)
                                    ?
                                    <>Download Transcript<FontAwesomeIcon icon={faRefresh} className='spinning-icon' /></>
                                    :
                                    <>Download Transcript<FontAwesomeIcon icon={faDownload} /></>
                                }
                            </button>
                        </div>
                        <hr className='no-top-margin' />
                        <div className='mc-list'>
                        {
                            (myMcList && Object.keys(myMcList).length > 0) 
                            ?
                            Object.values(myMcList)
                            .sort((a,b) => a.name > b.name ? 1 : -1)
                            .map(m => {
                                const progNum = userProgressNumbers ? userProgressNumbers[m.mc_key] : null;
                                console.log(progNum)
                                return (
                                <div key={m.mc_key}>
                                    {
                                        // (!progNum) //m.imported && (
                                        // ?
                                        // <div  className='g-list-item mc-card no-shadow'>
                                        //     <div className='icon-container'>
                                        //         <img src={m.imageUrl} className='icon' />
                                        //     </div>
                                        //     <div className='text-container'>
                                        //         <div className='title'>{m.name}</div>
                                        //         <div className='buttons'>
                                        //             {
                                        //                 (loading) 
                                        //                 ?
                                        //                 <button className='g-button'><ThreeDotsLoader /></button>
                                        //                 :
                                        //                 <button className='g-button' onClick={() => importOldProgress(m.id, m.mc_key)}>Import Data from v1</button>
                                        //             }
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        // :
                                        <div  className='g-list-item mc-card'>
                                            <div className='icon-container'>
                                                <img src={m.imageUrl} className='icon' />
                                            </div>
                                            <div className='text-container'>
                                                <div className='title'>{m.name}</div>
                                                Status:
                                                <div className="g-space-0-5"></div>
                                                {   
                                                    (loading.includes(m.mc_key)) 
                                                    ?
                                                    <div className='buttons'>
                                                        <button className='g-button disabled'><ThreeDotsLoader /></button>
                                                    </div>
                                                    : 
                                                    <div className='status-row'>
                                                        <StatusBarWithIcons progressNumber={progNum} mcData={myMcList[m.mc_key]} />
                                                        <button 
                                                            className='g-button'
                                                            onClick={() => navigate(`/microcredential/${m.mc_key}`)}    
                                                        >
                                                            Open<FontAwesomeIcon icon={faArrowRight} />
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            )})
                                
                            :
                            <p>No Micro-Credentials Yet...</p>
                        }
                        </div>
                    </div>
                }
            </div>
            <NewMcCheck />
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage} />
        </div>
    )
}

export default MyMicrocredentials;