import React, { useEffect, useRef, useState } from 'react'
import './evidence-card.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCheck, faPen, faSave, faUpload, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import EvidenceButton from '@/components/evidence-button/evidence-button.component';
import { toggleBoxDisplay } from '@/utils/showBox';
import { getUploadUrlFromPath } from '@/utils/getUrlFromFbPath';
import { useLocation } from 'react-router-dom';

const EvidenceCard = ({ 
    mcProgress, 
    title, 
    id, 
    hidden, 
    disabled, 
    formData, 
    evNumber, 
    chooseEvidenceFromModal, 
    saveExplanation, 
    removeEvidence, 
    sectionId,
    saveDirtyStatus,
}) => {

    const [ dbProgress, setDbProgress ] = useState(null);
    const [ submitted, setSubmitted ] = useState(false);
    const [ isDirty, setIsDirty ] = useState(false);
    const evidenceRef = useRef();
    const location = useLocation();

    useEffect(() => {
        if (!mcProgress || JSON.stringify(mcProgress) === JSON.stringify(dbProgress)) return;
        setDbProgress({...mcProgress});
        console.log(mcProgress);
    }, [mcProgress])

    useEffect(() => {
        if (saveDirtyStatus) {
            saveDirtyStatus(sectionId, evNumber, isDirty);
            // console.log('changing dirty status: ' + isDirty);
        }
    }, [isDirty])

    useEffect(() => {
        if (!mcProgress || !sectionId) return;
        // console.log(mcProgress);
        if (mcProgress.submitted) {
            if (Object.values(mcProgress.submitted)[Object.values(mcProgress.submitted).length - 1].retStatus === 'pending') {
                setSubmitted(true);
                return;
            }
        } else if (sectionId === 'precheck' && mcProgress.content_precheck && mcProgress.content_precheck.status === 'pending') {
            setSubmitted(true);
        }
    }, [mcProgress, sectionId])

    useEffect(() => {
        // console.log(formData)
        // console.log(dbProgress)
        if (!formData || !dbProgress || Object.values(dbProgress).length === 0) return;
        if (!sectionId  || sectionId === 'overview' || sectionId === 'closing') return;
        if (formData.exp[evNumber] || formData.upload[evNumber]) {
            const formContent = {...formData};
            const savedContent = {...dbProgress[`content_${sectionId}`]};
            if (
                !savedContent ||
                (!savedContent.exp || formContent.exp[evNumber] !== savedContent.exp[evNumber]) ||
                (!savedContent.upload || formContent.upload[evNumber] !== savedContent.upload[evNumber])
                // !dbProgress[`content_${sectionId}`] || 
                // formData.exp[evNumber] !== dbProgress[`content_${sectionId}`].exp[evNumber] || 
                // formData.upload[evNumber] !== dbProgress[`content_${sectionId}`].upload[evNumber]
            ) {
                setIsDirty(true);
            } else {
                setIsDirty(false);
            }
        } else {
            // console.log(sectionId + '-' + evNumber + ' is not dirty')
            setIsDirty(false);
        }
        if (saveDirtyStatus && formData.exp[evNumber] && !formData.upload[evNumber]) {
            saveDirtyStatus(sectionId, evNumber, 'upload');
        }
        else if (saveDirtyStatus && !formData.exp[evNumber] && formData.upload[evNumber]){
            saveDirtyStatus(sectionId, evNumber, 'explain');
        } 
        // else if (saveDirtyStatus) {
        //     saveDirtyStatus(sectionId, evNumber, false);
        // }
        
        
    }, [formData, dbProgress, sectionId])

    function showContent() {
        toggleBoxDisplay(id);
        const caret = document.getElementById(`${id}-caret`);
        caret.classList.toggle('rotate');
    }

    async function goToEvidenceLink(path) {
        const url = await getUploadUrlFromPath(path)
        window.open(url, '_blank');
    }

    return (
        <div className='evidence-card'>
            {
                (formData && mcProgress) &&
                <>
                <div className='evidence-title' onClick={() => showContent()}>
                    <div className='title-status'>
                        {title}
                        {
                            (formData.upload[evNumber] || formData.exp[evNumber])
                            ? 
                            (formData.exp[evNumber] && !formData.upload[evNumber])
                            ?
                            <div className='complete-check need-to-save'><FontAwesomeIcon icon={faUpload} size='lg' />Needs Upload</div>
                            :
                            (!formData.exp[evNumber] && formData.upload[evNumber])
                            ?
                            <div className='complete-check need-to-save'><FontAwesomeIcon icon={faPen} size='lg' />Needs Explanation</div>
                            : 
                            (isDirty)
                            ?
                            <div className='complete-check need-to-save'><FontAwesomeIcon icon={faSave} size='lg' />Need to save</div>
                            :
                            (submitted)
                            ?
                            <div className='complete-check pending'><FontAwesomeIcon icon={faCheck} size='lg' />Submitted</div>
                            :
                            (mcProgress[`content_${sectionId}`] && mcProgress[`content_${sectionId}`].status === 'pending')
                            ?
                            <div className='complete-check saved'><FontAwesomeIcon icon={faCheck} size='lg' />Saved</div>
                            :
                            (mcProgress[`content_${sectionId}`] && mcProgress[`content_${sectionId}`].status === 'approved')
                            ?
                            <div className='complete-check'><FontAwesomeIcon icon={faCheck} size='lg' />Approved</div>
                            :
                            <div className='complete-check saved'><FontAwesomeIcon icon={faCheck} size='lg' />Ready to submit</div>
                            :
                            null
                        }
                    </div>
                    <div className={`caret ${!hidden && 'rotate'}`} id={`${id}-caret`}>    
                        <FontAwesomeIcon icon={faCaretLeft}  size='lg'/>
                    </div>
                </div>
                <div className={`evidence-card-content ${hidden && 'hidden'}`} id={id}>
                    <div className="g-space-20"></div>
                    <div className='evidence-button-div'>
                        
                        {
                            (mcProgress && formData.upload[evNumber] && mcProgress.uploads && mcProgress.uploads[formData.upload[evNumber]])
                            ?
                            <>
                                Chosen Evidence: 
                                <div className='chosen-evidence'>
                                    <EvidenceButton evidenceData={{...mcProgress.uploads[formData.upload[evNumber]]}} onClick={() => goToEvidenceLink(mcProgress.uploads[formData.upload[evNumber]].link)} />
                                    {
                                        (!disabled) &&
                                        <>
                                            <button className='g-button med-btn text' onClick={() => chooseEvidenceFromModal(evNumber)}><FontAwesomeIcon icon={faPen} title="Change Upload" /></button>
                                            <button className='g-button med-btn text remove' onClick={() => removeEvidence(evNumber)}><FontAwesomeIcon icon={faXmarkCircle} title="Remove Upload"/></button>
                                        </>
                                    }
                                </div>
                            </>
                            :
                            <div className='chosen-evidence'>
                                <button className={`g-button ${disabled && 'disabled'}`} onClick={() => chooseEvidenceFromModal(evNumber)}>Choose or Upload an Artifact</button>
                                <p>No evidence chosen yet...</p>
                            </div> 
                        }
                    </div>
                    <hr />
                    <div className='explanation-div'>
                        <div className='field'>
                            <label>Evidence, Location, and Self-Analysis Narrative</label>
                            {
                                (disabled)
                                ?
                                <div className='g-list-item'>{formData.exp[evNumber]}</div>
                                :
                                <textarea rows='4' placeholder='Type your explanation here...' value={formData.exp[evNumber]} onChange={(e) => saveExplanation(evNumber, e.target.value)} ></textarea>
                            }
                        </div>
                    </div>

                </div>
                </>
            }
        </div>
    )
}

export default EvidenceCard;