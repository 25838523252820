import { useEffect, useRef } from 'react'

import './App.scss'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

// import LoadingPage from './pages/loading/loading-page.page'
import ProfilePage from './pages/profile/profile.page';
import LandingPage from './pages/landing-page/landing-page.comp';
import LogoutPage from './pages/logout/logout-page.page';
import PageNotFound from './pages/page-not-found/page-not-found.component';
import { auth } from './utils/firebase.utils';
import { onAuthStateChanged } from 'firebase/auth';
import { setCurrentUser } from './state/slices/userSlice';
import SSO from './pages/sso/sso.page';
import HomePage from './pages/home-page/home-page.page';
import RequireAuth from './utils/requireAuth';
import AdminPage from './pages/admin-page/admin.page';
import LoadingPage from './pages/loading/loading.page';
import McPage from './pages/mc-page/mc-page.page';
import SignInWithLink from './pages/sign-in/sign-in-with-link.component';
import EvaluatePage from './pages/evaluate-page/evaluate-page.page';
import { screenSizes } from './utils/responsiveQueries';
import { setScreenSize } from './state/slices/contentSlice';
import ChangeLog from './pages/changelog/changelog.page';

function Root() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector(state => state.userData.currentUser);
    const userProfile = useSelector(state => state.userData.userProfile);
    const screenSize = useSelector(state => state.contentData.screenSize);
    const screenRef = useRef();

    useEffect(() => {
        if (!userProfile) {return;}
        signInUser();
        
    }, [userProfile])

    useEffect(() => {
        // signInUser();
        

    }, [])

    useEffect(() => {
        if (!screenSizes) {return;}
        getScreenSize();
        onresize = (event) => {
            getScreenSize();
        };
    }, [screenSizes])

    function getScreenSize() {
        let sizeArr = screenSizes.filter(s => window.innerWidth >= s.minWidth && window.innerWidth <= s.maxWidth);
        let size;
        size = sizeArr[0].name;
        if (size !== screenRef.current) {
            screenRef.current = size;
            dispatch(setScreenSize(screenRef.current));
        }
    }
    
    function signInUser() {
        const href = window.location.href
        const currentlySignedInUser = auth.currentUser;
        // if (currentlySignedInUser) {
        //     saveUserToState(currentlySignedInUser)
        // }
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                // console.log(user)
                saveUserToState(user)
                // if (href.includes('/signin')) {
                //     navigate('/loading') 
                // }
                // navigate('/loading')  
            } else {
                let timeoutId;

                function timeoutFn() {
                    if (currentUser) {
                        navigate('/logout')
                        // console.log('logging out')
                    }
                    clearTimeout(timeoutId);
                }
                timeoutId = setTimeout(timeoutFn, 2000);
                // if (!href.includes('/signin') && !href.includes('/register') && !href.includes('/info')) {
                //     navigate('/')
                // }
            }
        });
    }

    function saveUserToState(user) {
        // console.log(user);
        
        const newUser = {
            displayName: user.displayName,
            email: user.email,
            metadata: {
                createdAt: Number(user.metadata.createdAt),
                lastLogInAt: user.metadata.lastLogInAt
            },
            uid: user.uid,
            photoURL: user.photoURL,
            // provider: user.providerData[0].providerId
        }
        dispatch(setCurrentUser({...newUser}));
    }

    return (
        <Routes>
                <Route path='' element={<LandingPage />}></Route>
                <Route path='/sso' element={<SSO />}></Route>
                <Route path='/signinwithlink' element={<SignInWithLink />}></Route>
                <Route path='/logout' element={<LogoutPage />}></Route>
                {/* <Route path='/loading/:refreshProfile' element={<LoadingPage />}></Route> */}
                <Route path='/profile' element={<ProfilePage />}></Route>
                {/* <Route path='/admin/*' element={<AdminPage />}></Route> */}
                <Route path='/home/*' element={<HomePage />}></Route>
                <Route path='/microcredential/:mc_key/*' element={<McPage />}></Route>
                <Route path='evaluate/:user_mc_keys/*' element={<EvaluatePage />}></Route>
                <Route path='/admin/*' element={<RequireAuth><AdminPage /></RequireAuth>}></Route>
                <Route path='/loading' element={<LoadingPage />}></Route>
                <Route path='/404' element={<PageNotFound />}></Route>
                <Route path='/changelog' element={<ChangeLog />}></Route>
                <Route path="*" element={<Navigate replace to="/404" />} />
                <Route path="/noaccess" element={<Navigate replace to="/404" />} />
            </Routes>
    );
}

export default Root
