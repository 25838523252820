import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './evaluating-mc-list.styles.scss';
import { getMcRegsFromDb } from '@/utils/getDataFromDb';
import { Link, Route, Routes } from 'react-router-dom';
import ViewEvalData from './view-eval-data.component';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import { useSelector } from 'react-redux';
import ThreeDotsLoaderSmall from '@/components/SHARED/loader/three-dots-loader-small.component';

const EvaluatingMcList = ({ 
    evaluatingList, 
    evalProgressNumbers,
    nfeRegs 
}) => {

    const [ getRegs, setGetRegs ] = useState(false);
    const [ activeParts, setActiveParts ] = useState({});
    const [ mcsPending, setMcsPending ] = useState({});
    const [ loading, setLoading ] = useState(true);
    const mcRef = useRef();
    const activePartRef = useRef();
    const pendingRef = useRef();
    let mcListener;
    let evalCount = 0;
    let retrieved = 0;
    let retrievedRegs = [];

    useEffect(() => {

        return () => {
            if (mcListener) {mcListener();}
        }
    }, [])

    useLayoutEffect(() => {
        if (!evaluatingList || Object.values(evaluatingList).length === 0) {return;}
        setGetRegs(true); // keeps script from running multiple times on refresh 
                          // it was running after rehydrate, then again after getting state data
        
    }, [evaluatingList])

    useEffect(() => {
        if (!evalProgressNumbers || !evaluatingList || Object.values(evaluatingList).length === 0 || !nfeRegs) return;
        console.log(evaluatingList)
        for (let e of Object.values(evaluatingList)) {
            if (!retrievedRegs.includes(e.mc_key)) {
                getMcAndUserData(e);
                retrievedRegs.push(e.mc_key);
            }
        }
        setLoading(false);
    }, [evaluatingList, evalProgressNumbers, nfeRegs])

    function getMcAndUserData(e) {
        evalCount = Object.values(evaluatingList).length
        // getMcRegsFromDb({'mc_key': e.mc_key, 'callback': callback, 'handleListener': handleListener})
        // function callback(data) {
        let regData = nfeRegs[e.mc_key];
        if (!regData) return;    
        let partCount = 0;
        let pending = false;
        const mcProgress = evalProgressNumbers[e.mc_key];
        for (let reg of Object.values(regData.regs)) {
            if (mcProgress && mcProgress[reg.cst_key] && mcProgress[reg.cst_key].progNum < 9) {
                partCount++;
            }
            if (mcProgress && mcProgress[reg.cst_key] && (mcProgress[reg.cst_key].progNum === 2 || mcProgress[reg.cst_key].progNum === 7)) {
                pending = true;
            }
        }
        activePartRef.current = {...activePartRef.current, ...{[e.mc_key]: {count: partCount}}}
        setActiveParts(activePartRef.current);
        pendingRef.current = {...pendingRef.current, ...{[e.mc_key]: pending}}
        setMcsPending(pendingRef.current);
        // }
        // function handleListener(unsub) {
        //     mcListener = unsub;
        // }
    }

    return (
        <div className='evaluating-list-container'>
            <div className='evaluating-list-content'>
                <Routes>
                    <Route path='' element={
                        <div className='g-card'>
                            <div className='section-title'>Micro-Credentials I'm Evaluating</div>
                            <hr className='no-top-margin' />
                            <div className='mc-list'>
                            {
                                (loading)
                                ?
                                <div className='center-col'><ThreeDotsLoader /></div>
                                :
                                (evaluatingList && nfeRegs) &&
                                Object.values(evaluatingList)
                                .filter(e => e.status === 'active')
                                .sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                                .map(mcData => (
                                    <div key={mcData.mc_key} >
                                        <Link to={mcData.mc_key}>
                                            <button className='g-button mc-card'>
                                                <div className='icon-container'>
                                                    <img src={mcData.imageUrl} className='icon' />
                                                </div>
                                                <div className='text-container'>
                                                    <div>
                                                        <div className='title'>{mcData.name}</div>
                                                        <div className='data'>
                                                            Active Participants: {
                                                                activeParts[mcData.mc_key]
                                                                ?
                                                                activeParts[mcData.mc_key].count
                                                                :
                                                                <ThreeDotsLoaderSmall />
                                                            }
                                                        </div>
                                                        <div className='data'>
                                                            Registrants: {nfeRegs[mcData.mc_key].count}
                                                        </div>
                                                    </div>
                                                    {
                                                        (mcsPending[mcData.mc_key]) &&
                                                        <div className='g-list-item pending'>
                                                            Approval Needed
                                                        </div>
                                                    }
                                                </div>
                                            </button>
                                        </Link>
                                    </div>
                                ))
                            }

                            {/* {
                                (evalRegs && evalProgressNumbers && evaluatingList) &&
                                Object.values(evaluatingList).filter(e => e.status === 'active')
                                .map(mcData => {
                                    if (!evalRegs[mcData.mc_key]) {return;}
                                    let partCount = 0;
                                    const mcRegs = evalRegs[mcData.mc_key].regs;
                                    const mcProgress = evalProgressNumbers[mcData.mc_key];
                                    // console.log(mcProgress);
                                    let pending = false;
                                    for (let cst_key of mcRegs.list) {
                                        if (mcRegs.roles[cst_key].includes('part') && (!mcProgress[cst_key] || mcProgress[cst_key].progNum < 9)) {
                                            partCount++;
                                        }
                                        if (mcProgress[cst_key] && (mcProgress[cst_key].progNum === 3 || mcProgress[cst_key].progNum === 7)) {
                                            pending = true;
                                        }
                                    }

                                    return (
                                        <div key={mcData.mc_key} >
                                            <Link to={mcData.mc_key}>
                                                <div className='g-list-item mc-card clickable'>
                                                    <div className='icon-container'>
                                                        <img src={mcData.imageUrl} className='icon' />
                                                    </div>
                                                    <div className='text-container'>
                                                        <div>
                                                            <div className='title'>{mcData.name}</div>
                                                            <div className='data'>
                                                                Active Participants: {partCount}
                                                            </div>
                                                        </div>
                                                        {
                                                            (pending) &&
                                                            <div className='g-list-item pending'>
                                                                Approval Needed
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })
                            } */}
                            </div>
                            {/* {
                                (loading) &&
                                <>
                                    <div className="g-space-2"></div>
                                    <div className='center'><ThreeDotsLoader /></div>
                                </>
                            } */}
                        </div> 
                    }></Route>
                    <Route path=':mc_key' element={
                        <ViewEvalData 
                            nfeRegs={nfeRegs}
                            evalProgressNumbers={evalProgressNumbers}
                            evaluatingList={evaluatingList} 
                        />
                    }></Route>
                </Routes>
            </div>    
        </div>
    )
}

export default EvaluatingMcList