import React, { useEffect, useState } from 'react'
import './materials-page.styles.scss';

import TitleCard from '../title-card/title-card.component';
import { useNavigate } from 'react-router-dom';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import { getUploadUrlFromPath } from '@/utils/getUrlFromFbPath';

const MaterialsPage = ({ mcData, mcProgress}) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ markedAsComplete, setMarkedAsComplete ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ materialsLink, setMaterialsLink ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!mcData) return;
        getMaterialsLink();

    }, [mcData])
    
    async function getMaterialsLink() {
        const url = await getUploadUrlFromPath(mcData.materials);
        setMaterialsLink(url);
    }

    async function saveMaterialData() {
        if (!markedAsComplete) {
            setAlertMessage('Please mark this section as completed before moving on.')
            return;
        }
        setLoading(true);
        const res = await UseCloudFunction('saveMcUserContent', {
            'profile': userProfile, 
            'mc_key': mcData.mc_key,
            'path': 'content_materials',
            'action': 'materials-viewed',
            'content': {
                'id': 'materials',
                'timestamp': new Date().getTime(),
            }
        })
        console.log(res);
        if (res.error) {
            setAlertMessage('Something went wrong. Please try again later.')
            return;
        }


        navigate(-1);
    }

    return (
        <div className='materials-page'>    
            <TitleCard mcData={mcData} title='Micro-Credential Materials' />
            
            <div className="g-card">
                <div className='card-head'>Step 1: Micro-Credential Download and Overview</div>
                <hr />

                <div className='video-div'>
                    <p>Download the <b>Micro-Credential Document</b> and study the requirements and procedures for this Micro-Credential.</p>
                    {
                        (materialsLink) &&
                        <a href={materialsLink} download target='_blank'>
                            <button className="g-button">
                                Download Micro-Credential Document
                            </button>
                        </a>
                    }
                </div>

                <hr />
                {/* <div className='g-space-40'></div> */}
                
                <div className='video-div'>
                    <p>Next, watch this video explaining the content of the Micro-Credential Document</p>
                    <iframe src="https://player.vimeo.com/video/492492699?color=207ec2&title=0&byline=0&portrait=0" width="640" height="360" frameBorder="0" allow="fullscreen"></iframe>
                </div>
            </div>

            <div className="g-card">
                <div className='card-head'>Step 2: ELN Community</div>
                <hr />
                
                <div className='video-div'>
                    <p>Watch this video explaining the ELN Community, a source of support and resources.</p>
                    <iframe src="https://player.vimeo.com/video/490792461?color=207ec2&title=0&byline=0&portrait=0" width="640" height="360" frameBorder="0" allow="fullscreen"></iframe>
                </div>
            </div>
            
            <div className="g-card">
                <div className='card-head'>Step 3: Micro-Credential Portal Video</div>
                <hr />
                            
                <div className='video-div'>
                    <p>Watch these videos explaining the purpose and processes you will complete to submit your Micro-Credential.</p>
                    <p><b>EVIDENCE SUBMISSION AND SELF-ANALYSIS</b></p>
                    <iframe src="https://player.vimeo.com/video/492103932?color=207ec2&title=0&byline=0&portrait=0" width="640" height="360" frameBorder="0" allow="fullscreen"></iframe>
                </div>
                <div className='video-div'>
                    <p><b>PRE-CHECK ARTIFACT UPLOAD</b></p>
                    <iframe src="https://player.vimeo.com/video/491220145?color=207ec2&title=0&byline=0&portrait=0" width="640" height="360" frameBorder="0" allow="fullscreen"></iframe>
                </div>
                <div className='video-div'>
                    <p><b>COMPLETE PORTFOLIO UPLOAD AND SUBMISSION</b></p>
                    <iframe src="https://player.vimeo.com/video/491808325?color=207ec2&title=0&byline=0&portrait=0" width="640" height="360" frameBorder="0" allow="fullscreen"></iframe>
                </div>
                <div className='video-div'>
                    <p><b>EVALUATION AND BADGING</b></p>
                    <iframe src="https://player.vimeo.com/video/492504897?color=207ec2&title=0&byline=0&portrait=0" width="640" height="360" frameBorder="0" allow="fullscreen"></iframe>
                </div>
            </div>
            
            <div className="g-card">
                {
                    (mcProgress.content_materials)
                    ?
                    <div>
                        <div className='card-head'>Step 4: Continue</div>
                        <hr />
                        <div className='buttons'>
                            <button className='g-button' onClick={() => navigate(-1)}>Continue</button>
                        </div>
                    </div> 
                    :
                    <div>
                        <div className='card-head'>Step 4: Mark these steps as completed</div>
                        <hr />
                        <div className='field-checkbox'>
                            <label>
                                <input type="checkbox" onChange={(e) => setMarkedAsComplete(e.target.checked)} />
                                <span className="checkmark"></span>
                                I have read and viewed the materials presented here, and I am ready to provide my evidence.
                            </label>
                        </div>
                        <div className="g-space-40"></div>
                        <div className='buttons'>
                            <button className='g-button' onClick={() => navigate(-1)}>Cancel</button>
                            {
                                (loading)
                                ?
                                <button className='g-button'><ThreeDotsLoader /></button>
                                :
                                (markedAsComplete)
                                ?
                                <button className='g-button primary' onClick={() => saveMaterialData()}>Continue to Next Step</button> 
                                :
                                <button className='g-button disabled'>Continue to Next Step</button> 
                            }
                        </div>
                    </div> 
                }
            </div>
        </div>
    )
}

export default MaterialsPage