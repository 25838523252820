import React, { useEffect, useState } from 'react'
import './find-user-in-db.styles.scss'
import { UseCloudFunction } from '@/utils/firebase.utils'
import { useSelector } from 'react-redux'
import { ModalAlert } from '@/components/SHARED/modal/modal.component'
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component'
import { useLocation } from 'react-router-dom'

const FindUserInDb = ({ users, user_cst_key, email, saveData, searchDb, setSearchDb, dbData, setDbData }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ loading, setLoading ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (searchDb && userProfile) {
            getUser(true);
        }
    }, [searchDb, userProfile])
    
    async function getUser(edit) {
        if (!email) {
            setAlertMessage('Please include a valid email.');
            return;
        }
        setLoading(true);
        const res = await UseCloudFunction(
            'getUserFromDb', 
            {
                'user_cst_key': user_cst_key,
                'email': email, 
                'cst_key': userProfile.cst_key
            }
        )
        console.log(res)
        let newUser;
        setLoading(false);
        if (res[email].response === 'NO ACCOUNT FOUND') {
            setAlertMessage('Sorry, no user found with this email address.');
            return;
        }
        if (res[email].response.includes('MULTIPLE')) {
            newUser = res[email].dbData[0];
        } else {
            newUser = res[email].dbData;
        }
        if (
            location.pathname.includes('/importuser/') &&
            !edit && 
            users[newUser.ind_cst_key]
        ) {
            setAlertMessage('This user is already included in the Micro-Credential platform.');
            return;
        }
        
        saveData('email', newUser.cst_eml_address_dn);
        saveData('fName', newUser.ind_first_name);
        saveData('lName', newUser.ind_last_name);
        saveData('org', newUser.cst_org_name_dn);
        saveData('id', newUser.cst_eml_address_dn.replace(/[^\w]+/g, '').toLowerCase())
        
        setDbData(newUser);
       
        setSearchDb(false);
    }
    
    return (
        <div className='find-user-in-db'>
            <button className='g-button' onClick={() => getUser()}>
                {
                    (loading) 
                    ?
                    <ThreeDotsLoader />
                    :
                    (location.pathname.includes('/importuser/'))
                    ?
                    <span>Find User in NetForum</span>
                    :
                    <span>Update User Data</span>
                }
            </button>
            
            <div className="g-space-20"></div>
            {
                (dbData) && 
                <div className="result-div g-card no-shadow">
                    <b>First Name:</b> {dbData.ind_first_name}<br />
                    <b>Last Name:</b> {dbData.ind_last_name}<br />
                    <b>Email:</b> {dbData.cst_eml_address_dn}<br />
                    <b>Org:</b> {dbData.cst_org_name_dn}<br />
                    <b>Position:</b> {dbData.cst_ixo_title_dn}<br />
                    <b>City:</b> {dbData.adr_city}<br />
                    <b>State:</b> {dbData.adr_state}<br />
                    <b>User Key:</b> {dbData.ind_cst_key}<br />
                    <b>Record No:</b> {dbData.cst_recno}
                </div>
            }
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
        </div>
    )
}

export default FindUserInDb;