import React, { useState } from 'react'
import './top-bar.styles.scss'

import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import logo from '@/assets/eln-logo-full.png'
import UserDropdown from '@/components/layout-auth/head-bar/user-dropdown.component'
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component'

const TopBar = () => {

    const currentUser = useSelector(state => state.userData.currentUser)
    const userProfile = useSelector(state => state.userData.userProfile);
    const dbData = useSelector(state => state.userData.dbData)
    const [ showDropdown, setShowDropdown ] = useState(false);
    const location = useLocation();

    return (
        <div className='top-bar'>
            <div className='top-bar-content-left'>
                <Link to="/home">
                    <div className='top-bar-content-left-link'>
                        <img src={logo} className='top-bar-logo-left' />
                        <div className="menu-spacer"></div>
                        <div className='top-bar-text'>Micro-credential Portal</div>
                    </div>
                </Link>
            </div>
            <div className='top-bar-content-right'>
                {
                    (currentUser && dbData) &&
                    <div className='top-bar-menu'>
                        {
                            (location.pathname !== '/home') &&
                            <Link to='/home'>
                                <button className='g-button static'>
                                    Home
                                </button>
                            </Link>
                        }
                        {/* {
                            (dbData.Admin && !location.pathname.includes('/admin')) &&
                            <Link to='/admin'>
                                <div className='top-bar-menu-item'>
                                    Admin
                                </div>
                            </Link>
                        } */}
                        <div className="menu-spacer"></div>
                        <div className='g-button static' onClick={() => setShowDropdown(!showDropdown)}>
                            {userProfile.fName} {userProfile.lName}
                            <UserIcon profile={userProfile} reverse={true} />
                        </div>
                        
                    </div>
                }
                <UserDropdown show={showDropdown} />
            </div>
            
        </div>
    )
}

export default TopBar