import React, { useEffect, useState } from 'react'
import './mc-progress-container.styles.scss';
import McHeader from '../mc-header/mc-header.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate, faFileLines, faFlagCheckered, faListCheck, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { findCurrentProgress } from '@/utils/findCurrentProgress';
import ThreeDotsLoader from '@/components/SHARED/loader/three-dots-loader.component';
import { useSelector } from 'react-redux';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { getPartEmailData } from '@/utils/getPartEmailData';

const McProgressContainer = ({ mcData, mcProgress, progressNumber }) => {
    
    const userProfile = useSelector(state => state.userData.userProfile);
    const [ progNumber, setProgNumber ] = useState(0);
    const [ display, setDisplay ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!mcData || !mcProgress || !progressNumber) return;
        setProgNumber(progressNumber.progNum);
        setDisplay(true);
    }, [mcData, mcProgress, progressNumber])

    async function acceptBadge() {
        window.open(mcData.evalLink, "_blank");
        const res = await UseCloudFunction(
            "saveEarnedBadge",
            {
                "mcData": mcData,
                "profile": userProfile,
            }
        )
        console.log(res);
    }

    return (
        <div className='mc-progress-container'>
            {
                (display) ?
                <div className='g-card'>
                    <McHeader mcData={mcData} />
                    <hr />
                    <p>{mcData.summary}</p>
                    <div className='g-space-30'></div>
                    <div className='progress-bar'>
                        <button className={`g-button text-only progress-button ${progNumber >= 1 ? 'clickable complete' : 'clickable'}`} onClick={() => navigate('materials')}>
                            <FontAwesomeIcon icon={faFileLines} size='3x' className='icon' />
                            <div className='text'>
                                <div className='title'>Get Started</div>
                                <div className='desc'>Click here to access the resources and video tutorials for this Micro-Credential.</div>
                            </div>
                            <div className='status'>
                                <b>Status: </b>
                                {
                                    progNumber >= 1 ? 'Complete' : 'Not Started'
                                }
                            </div>
                        </button>
                        <button 
                            className={
                                `g-button text-only progress-button ${
                                    progNumber >= 4 ? 'clickable complete' : 
                                    progNumber === 3 ? 'clickable declined' : 
                                    progNumber === 2 ? 'clickable pending' : 
                                    progNumber >= 1 ? 'clickable' : ''
                                }`
                            } 
                            onClick={() => navigate('precheck')}
                        >
                            <FontAwesomeIcon icon={faUpload} size='3x' className='icon' />
                            <div className='text'>
                                <div className='title'>Upload Precheck Artifact</div>
                                <div className='desc'>Upload a Precheck Artifact and provide a Self Analysis of that artifact.</div>
                            </div>
                            <div className='status'>
                                <b>Status: </b>
                                {
                                    progNumber === 1 ? 'Not Started' :
                                    progNumber === 2 ? 'Pending' : 
                                    progNumber === 3 ? 'Resubmit Changes' : 
                                    progNumber >= 4 ? 'Complete': ''
                                }
                            </div>
                        </button>
                        <button
                            className={
                                `g-button text-only progress-button ${
                                    progNumber >= 9 ? 'clickable complete' : 
                                    progNumber === 8 ? 'clickable declined' : 
                                    progNumber >= 7 ? 'clickable pending' : 
                                    progNumber > 3 && progNumber < 7 ? 'clickable' : ''
                                }`
                            } 
                            onClick={() => navigate('portfolio')}
                        >
                            <FontAwesomeIcon icon={faListCheck} size='3x' className='icon' />
                            <div className='text'>
                                <div className='title'>Build And Submit Your Portfolio</div>
                                <div className='desc'>Upload the rest of the Artifacts that meet the requirements, and provide analyses of these artifacts.</div>
                            </div>
                            <div className='status'>
                                <b>Status: </b>
                                {
                                    progNumber <= 4 ? 'Not Started' : 
                                    progNumber === 5 ? 'In Progress' : 
                                    progNumber >= 9 ? 'Approved' :
                                    progNumber === 6 ? <span className='ready-to-submit'>Ready to Submit</span> : 
                                    progNumber === 7 ? 'Pending' : 
                                    mcProgress.submissionsRemaining === 0 ? 'No Attempts Remaining' :
                                    progNumber === 8 ? 'Resubmit Changes' : 
                                    ''
                                }
                            </div>
                        </button>
                        {/* <div className={`g-card ${progNumber === 8 ? 'clickable done' : progNumber === 7 ? 'clickable' : ''}`}>
                            <FontAwesomeIcon icon={faFlagCheckered} size='3x' className='icon' />
                            <div className='text'>
                                <div className='title'>Submit Your Completed Portfolio</div>
                                <div className='desc'>Submit your portfolio for consideration by this Micro-Credential's evaluator.</div>
                            </div>
                            <div className='status'>{submitted.includes('pending') ? 'Status: Pending' : submitted.includes('complete') ? <span><b>Status:</b> Complete</span> : 'Status: Not Started'}</div>
                        </div> */}
                        <button 
                            className={
                                `g-button text-only progress-button ${
                                    progNumber === 9 ? 'clickable' : 
                                    progNumber === 10 ? 'complete finalized' : ''
                                }`
                            }
                            onClick={() => acceptBadge()}
                        >
                            <FontAwesomeIcon icon={faCertificate} size='3x' className='icon' />
                            <div className='text'>
                                <div className='title'>Complete the Evaluation and Get Your Badge</div>
                                <div className='desc'>After your portfolio has been approved, complete the evaluation and receive your Micro-Credential badge!</div>
                            </div>
                            <div className='status'>
                                <b>Status: </b>
                                {
                                    progNumber < 10 ? 'Incomplete' : 
                                    progNumber >= 10 ? 'Complete!': 
                                    ''
                                }
                            </div>
                        </button>
                    </div>
                </div>
                :
                <div className='center-col'>
                    <ThreeDotsLoader />
                </div>
            }
        </div>
    )
}

export default McProgressContainer